@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,200;0,500;0,700;1,400;1,500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: 'Manic';
  src: url("assets/fonts/MANIC-Regular.woff") format("woff");
}

/* styles from external CSS file */
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
  overflow: hidden;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;

  font-family: "Manic", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 22px;
  line-height: 1.4;

  color: #333333;
  background: #151515;
  overflow: hidden;
}

#root {
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h4 {
  font-family: 'Manic', sans-serif;
}

p {
  text-align: left;
}

h4 {
  font-size: 1.8em;
  margin-bottom: 18px;
}



.cred li {
  padding: 0 25px;
}

.cred p {
  text-align: right;
}

.cred ul, li {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  text-align: right;
}

h1 {
  color: #1300d8;
  font-size: 6em;
  text-align: left;
  margin: 0 .15em;
}

.spread {
  margin: 80px auto;
  color: #1300d8;
}

.blue-spread {
  background-color: #1300d8;
  color: #eeeeee;
}

.spread-end {
  justify-content: flex-end;
}

@media screen and (max-width: 801px) {
 h1{
   font-size: 3em;
   margin: 0 .25em;

 }

  h4{
    font-size: 1.2em;
  }
}
