.App {
  max-width: 1284px;
  height: 100%;
  text-align: center;
  flex: 1;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.App main {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
}

.a6-container {
  position: relative;
  width: 110mm; /* A6 width in millimeters */
  height: 152mm; /* A6 height in millimeters */
  background-image: url("./assets/images/front-cover.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #1300d8;
}

.glass-morphism-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2.5px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 2px 1px;
}

.a6-container p {
  text-align: center;
  margin-top: 8px;
  color: #1300d8;
}

.a6-container p strike {
  text-decoration-style: solid;
  text-decoration-thickness: 4px;
}

.a6-container p span {
  font-family: "Helvetica Neue", SansSerif;
  font-weight: 800;
  color: #1300d8;
}


@media screen and (max-width: 801px) {
  .a6-container {
    height: 172mm;
  }
}
